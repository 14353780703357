body {
  margin: 0;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif
}
.ory-controls-mode-toggle-control-group {
  /** 
  Sino modifiquem el display, la barra de icones d'accions de l'editor sempre 
  està dins del que ocupa l'informació que hi ha en l'editor, 
  Això provoca que inicialment la barra és horitzontal. 
  Modificant el display de flex a block, provoquem que la barra sempre és vertical
  però tenir-ho en compte si es volguesin tenir dos editors en una mateixa pàgina.
  */
  display: block !important;
}

.divider-mark-page {
  left: 0;
  right: 0;
  width: 100%;
  height: 1px;
  z-index: 10;
  position: absolute;
  background-color: #ff0000;
}